import styled from 'styled-components'
import { Link } from 'react-router-dom'

const SearchWrapper = styled.div`
  height: 100%;
  display: grid;
  @media (min-device-width: 600px) {
    grid-template-columns: 300px auto;
  }
  @media (max-device-width: 599px) {
    grid-template-rows: max-content auto;
  }
`
const SearchSidebar = styled.div`
  z-index: 1;
  display: grid;
  
  @media (min-device-width: 600px) {
    padding: 1.5rem;
    overflow-y: auto;
    grid-template-rows: max-content auto max-content;
  }
  @media (max-device-width: 599px) {
    padding: 1rem 1rem 0 1rem;
    grid-template-rows: max-content max-content;
  }
`
const SearchBody = styled.div`
  background: var(--white);
  @media (min-device-width: 600px) {
    overflow-y: auto;
    padding: 2rem;
  }
  @media (max-device-width: 599px) {
    padding: 1rem;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
`
const IconKeywordWrapper = styled.div`
  @media (max-device-width: 599px) {
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: .5rem;
  }
`
const LogoIcon = styled(Link)`
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  background: url('/favicon.png');
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
  cursor: pointer;
  margin: 0 0 1rem;
  opacity: .9;

  transition: all .2s ease;
  &:hover,
  &:active {
    transform: scale(1.01);

    &:after {
      left: -11px;
    }
  }

  &:after {
      content: '';
      position: absolute;
      left: -10px;
      top: 12px;
      width: 8px;
      height: 8px;
      border-color: var(--red80);
      border-width: 0 0 2px 2px;
      border-style: solid;
      transform: rotate(45deg);
      transition: all .2s ease;
    }

  @media (max-device-width: 599px) {
    margin: 4px;
  }

`

export {
  SearchWrapper,
  SearchSidebar,
  SearchBody,
  IconKeywordWrapper,
  LogoIcon,
}
