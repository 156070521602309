import React from 'react'

import withIsMobile from '../../util/withIsMobile'
import { AppConsumer } from '../../App'
import Footer from '../home/Footer'
import Results from './results'
import Keywords from './Keywords'
import Filters from './filters'
import {
  SearchWrapper,
  SearchSidebar,
  SearchBody,
  IconKeywordWrapper,
  LogoIcon,
} from '../../styles/search'

const Search = ({isMobile}) => (
  <AppConsumer>
    { ({state, actions}) => (
      <SearchWrapper>

        <SearchSidebar>
          <IconKeywordWrapper>
            <LogoIcon to="/">dope domains</LogoIcon>
            <Keywords {...state} {...actions} />
          </IconKeywordWrapper>
          <Filters />
          {!isMobile && <Footer />}
        </SearchSidebar>

        <SearchBody>
          { state.results && <Results /> }
        </SearchBody>
        
      </SearchWrapper>
    )}
  </AppConsumer>
)

export default withIsMobile(Search)
