import styled from 'styled-components'
import { injectGlobal } from 'styled-components'

// GLOBAL STYLES
injectGlobal`
  .modalContainer {
    /* background: var(--gray90) !important; */
    transition: all .2s ease;
  }
  .modalWindowContainer {
    position: absolute;
    top: 10vh;
    bottom: 0;
  }
`

const ModalWindow = styled.div`
  background: white;
  z-index: 100;
  padding: 2rem 2rem 0 2rem;
  display: grid;
  grid-template-rows: 3rem auto;
  
  @media (min-device-width: 600px) {
    width: 400px;
    height: 80vh;
    border-radius: var(--borderRadius);
  }
  @media (max-device-width: 599px) {
    width: 100vw;
    height: 80vh;
    transform: translate(0, 10vh);
    box-shadow: 0 100px 0 white; /* for bounce animation */
    border-radius: var(--borderRadius);
  }

`
const ModalTitle = styled.div`
  font-size: var(--size-xl);
  font-weight: 500;
  padding: 0 0 .5rem;
`
const ModalBody = styled.div`
  position: relative;
`
const WhiteFade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: -2rem;
  height: 1rem;
  background: linear-gradient( hsla(0, 0%, 100%, 1), hsla(0, 0%, 100%, 0) );
  z-index: 1;
  pointer-events: none;
`
const ModalScroller = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: -2rem;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0.5rem 0 0; /* keep white fade from clipping content */
`

export {
  ModalWindow,
  ModalTitle,
  ModalBody,
  WhiteFade,
  ModalScroller,
}