import ReactGA from 'react-ga'

// when a filter checkbox is checked or unchecked
// takes an update with the item that's changed and its new value
const updateFilterItem = (prevState, update) => {
  const filters = Object.assign(prevState.filters, {})
  const filterToChangeIndex = filters.findIndex(f => f.id === update.filter)
  const itemToChangeIndex = filters[filterToChangeIndex].items.findIndex(i => i.id === update.item)
  filters[filterToChangeIndex].items[itemToChangeIndex].allow = update.value
  const numFilterItemsSelected = filters.map(f => f.items.filter(i => i.allow)).reduce( (a, b) => a + b.length, 0)
  ReactGA.event({
    category: 'User',
    action: update.value ? 'Check filter item' : 'Uncheck filter item',
  })
  return {
    filters,
    numFilterItemsSelected,
  }
}

export default updateFilterItem
