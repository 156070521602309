// Higher-Order Component for React-Responsive
// wraps any component and gives it an isMobile prop

import React from 'react'
import MediaQuery from 'react-responsive'

const withIsMobile = (Component) => {
  return class extends React.Component {
    render() {
      return <MediaQuery maxDeviceWidth={599}>
        {matches => <Component isMobile={matches} {...this.props} /> }
      </MediaQuery>
    }
  }
}

export default withIsMobile
