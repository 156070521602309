import styled from 'styled-components'
import {keyframes} from 'styled-components'

const Label = styled.label`
  position: relative;
  display: block;
  cursor: pointer;
  line-height: 1.4;
  color: var(--gray40);
  user-select: none;
  opacity: .8;

  @media (min-device-width: 600px) {
    font-size: var(--size-m);
    padding: .25rem 0 .25rem 2rem;
  }
  @media (max-device-width: 599px) {
    font-size: var(--size-l);
    padding: .5rem 0 .5rem 2rem;
  }

  &:hover {
    opacity: 1;
  }
`

const CheckboxHidden = styled.input`
  display: none;
`

// https://codepen.io/theigmo87/pen/cwHyK
const bottomCheck = keyframes`
  0% { height: 0; }
  100% { height: ${20/2};  }
`
const topCheck = keyframes`
  0% { height: 0; }
  50% { height: 0; }
  100% { height: ${20 * 1.2}; }
`

const CheckboxVisible = styled.div`
  position: absolute;
  left: 0;
  background: ${p => p.checked ? 'var(--gray90)' : 'var(--white)'};
  border-radius: 2px;
  outline: none;

  @media (min-device-width: 600px) {
    top: .5rem;
  }
  @media (max-device-width: 599px) {
    top: .8rem;
  }

  height: 18px;
  width: 18px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-color: var(--gray90);
  transition: border-color ease 100ms;
  cursor: pointer;

  &::before, &::after {
    box-sizing: border-box;
    position: absolute;
    width: 3px;
    background-color: var(--gray40);
    display: inline-block;
    transform-origin: left top;
    border-radius: 5px;
    content: ' ';
    transition: opacity ease .5;
  }

  &::before {
    top: 14px;
    left: 7px;
    transform: rotate(-135deg);
    height: ${p => p.checked ? 16 : 0}px;
    /* animation: ${bottomCheck} 100ms ease 0s forwards; */
  }

  &::after {
    top: 8px;
    left: 0px;
    transform: rotate(-45deg);
    height: ${p => p.checked ? 9 : 0}px;
    /* animation: ${topCheck} 200ms ease 0s forwards; */
  }
`

const FilterItemExample = styled.div`
  color: var(--textSecondary);
  font-size: var(--size-s);
  display: none;
`

export {
  Label,
  CheckboxHidden,
  bottomCheck,
  topCheck,
  CheckboxVisible,
  FilterItemExample,
}