import React from 'react'
import Drawer from 'react-drag-drawer'

import withIsMobile from '../../../util/withIsMobile'
import {
  CloseX
} from '../../../styles/components'
import {
  ModalWindow,
  ModalTitle,
  ModalBody,
  WhiteFade,
  ModalScroller,
} from '../../../styles/modal'
import FilterItems from './FilterItems'

class FilterModal extends React.Component {

  closeFilter = () => {
    this.props.actions.toggleFilter({
      filter: this.props.filter.id,
      value: false,
    })
  }

  render() {
    // https://www.npmjs.com/package/react-drag-drawer
    return <Drawer
      open={!!this.props.filter.open}
      onRequestClose={this.closeFilter}
      modalElementClass="modalWindowContainer"
      containerElementClass="modalContainer"
    >
      <ModalWindow>
        <CloseX onClick={this.closeFilter}>&times;</CloseX>
        <ModalTitle>{this.props.filter.displayName}</ModalTitle>
        <ModalBody>
          <WhiteFade></WhiteFade>
          <ModalScroller>
            <FilterItems filter={this.props.filter} />
          </ModalScroller>
        </ModalBody>
      </ModalWindow>
    </Drawer>
  }
}

export default withIsMobile(FilterModal)