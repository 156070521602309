import React from 'react'

import apiCodes from '../../../util/apiCodes'
import {
  OutboundLink
} from '../../../styles/components'
import {
  BuyButton,
  Preview,
} from '../../../styles/domain'

const ResultPopup = ({result}) => {
  const status = apiCodes[result.apiSummary]

  return <div>

    { status && status.available !== 'available' ?
      <Preview
        to={`http://${result.domain}/`}
        eventLabel="Search result website"
        target="_blank"
        rel="noopener noreferrer"
        style={{backgroundImage: `url('//image.thum.io/get/width/600/crop/600/http://${result.domain}/')`}}
        />
      :
      <BuyButton
        to={`https://domainr.p.mashape.com/v2/register?mashape-key=${process.env.REACT_APP_MASHAPE_KEY}&domain=${result.domain}`}
        eventLabel="Click buy"
        target="_blank"
        rel="noopener noreferrer"
      />
    }

    <p>
      Search:
      {' '}
      <OutboundLink
        to={`https://www.google.com/search?q=${result.domainName}`}
        eventLabel="Search result on Google"
        target="_blank"
        rel="noopener noreferrer"
      >
        Google
      </OutboundLink>
      {' · '}
      <OutboundLink
        to={`https://knowem.com/checkusernames.php?u=${result.domainName}`}
        eventLabel="Search result in social media names"
        target="_blank"
        rel="noopener noreferrer"
      >
        Social media
      </OutboundLink>
      {' · '}
      <OutboundLink
        to={`https://www.trademarkia.com/trademarks-search.aspx?tn=${result.keywords.join(' ')}`}
        eventLabel="Search result in trademarks"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trademarks
      </OutboundLink>
      {' · '}
      <OutboundLink
        to={`https://whois.icann.org/en/lookup?name=${result.domainName}`}
        eventLabel="Search result WHOIS"
        target="_blank"
        rel="noopener noreferrer"
      >
        WHOIS
      </OutboundLink>
    </p>
  </div>
}

export default ResultPopup
