import React from 'react'
import { AppLink } from '../styles/components'

const Error = () => (
  <div style={{padding: '10vh'}}>
    <span role="img" aria-label="sad-face" style={{fontSize: '3rem', display: 'block'}}>😫</span>
    <h2>This page doesn't exist</h2>
    <p>This is definitely not dope... maybe it moved, or maybe the website is just broken.</p>
    <p>Either way, it's our fault—sorry!</p>
    <AppLink to="/">Back to Dope Domains home</AppLink>
  </div>
)

export default Error
