import styled from 'styled-components'

const FiltersWrapper = styled.div`
  user-select: none;
  @media (min-device-width: 600px) {
    display: grid;
    align-content: start;
    grid-row-gap: 1rem;
    margin-top: 1rem;
  }
  @media (max-device-width: 599px) {
    margin: 0 -1rem;
    white-space: nowrap;
    padding: 0 1rem 1rem 0;
    z-index: 300;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100vw;
  }
`

const FilterWrapper = styled.div`
  @media (min-device-width: 600px) {
    width: calc(300px - 3rem);
  }
  @media (max-device-width: 599px) {

    /* TODO - use grid here too? */
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 1rem;
    max-width: 200px;
  }
`

const FilterBox = styled.div`
  opacity: ${p => p.loading ? .5 : 1};
  border-radius: 6px;
  padding: .5rem 1rem;
  background: ${p => p.active ? 'var(--red60)' : 'hsla(20, 100%, 60%, 0.15)'};
  color: ${p => p.active ? 'var(--white)' : 'var(--textPrimary)'};
  user-select: none;
  font-weight: 500;

  cursor: pointer;
  opacity: .9;
  transition: all .2s ease;
  -webkit-appearance: none;

  &:hover,
  &:active {
    opacity: 1;
    transform: scale(1.01);
  }
`
const FilterHeader = styled.div`
  font-size: 1rem;
  line-height: 1.3;
  
  @media (min-device-width: 600px) {
    display: inline-block;
    user-select: none;
    margin-right: .5rem;
  }
  @media (max-device-width: 599px) {
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 0 .25rem;
  }
`
const FilterSubHeader = styled.div`
  line-height: 1;
  margin: 0 0 3px;
  font-size: var(--size-s);
  opacity: .5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export {
  FiltersWrapper,
  FilterWrapper,
  FilterBox,
  FilterHeader,
  FilterSubHeader,
}