import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

import withIsMobile from '../../util/withIsMobile'
import { Input } from '../../styles/components'

class Keywords extends React.Component {

  componentDidMount() {
    const query = this.props.match.params.query
    this.props.onKeywordType(query)
    const keywords = query ? query.split(' ') : []
    this.props.updateKeywords(keywords)
  }
  _onChange = (event) => {
    this.props.onKeywordType(event.target.value)
  }

  _onSubmit = (event) => {
    ReactGA.event({
      category: 'User',
      action: 'Change keywords',
    })

    event.preventDefault()

    this.props.updateKeywords(this.props.inputText.split(' '))
    this.props.history.replace('/search/'+this.props.inputText)
  }

  render() {
    return <form onSubmit={this._onSubmit}>
      <Input
        type="search"
        value={this.props.inputText}
        onChange={this._onChange}
        autoFocus={!this.props.isMobile}
        placeholder='ex: "bob cafe"'
      />
    </form>
  }
}

export default withIsMobile(withRouter(Keywords))
