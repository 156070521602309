const suffixes = [
  {
    id: "a",
    displayName: "-a",
    allow: false,
    score: -10,
  },
  {
    id: "y",
    displayName: "-y",
    allow: false,
    score: -10,
  },
  {
    id: "t",
    displayName: "-t",
    allow: false,
    score: -10,
  },
  {
    id: "s",
    displayName: "-s",
    allow: false,
    score: -10,
  },
  {
    id: "o",
    displayName: "-o",
    allow: false,
    score: -10,
  },
  {
    id: "n",
    displayName: "-n",
    allow: false,
    score: -10,
  },
  {
    id: "i",
    displayName: "-i",
    allow: false,
    score: -10,
  },
  {
    id: "e",
    displayName: "-e",
    allow: false,
    score: -10,
  },
  {
    id: "to",
    displayName: "-to",
    allow: false,
    score: -10,
    example: "aviato",
  },
  {
    id: "io",
    displayName: "-io",
    allow: false,
    score: -10,
  },
  {
    id: "de",
    displayName: "-de",
    allow: false,
    score: -10,
  },
  {
    id: "be",
    displayName: "-be",
    allow: false,
    score: -10,
  },
  {
    id: "ea",
    displayName: "-ea",
    allow: false,
    score: -10,
  },
  {
    id: "ed",
    displayName: "-ed",
    allow: false,
    score: -10,
  },
  {
    id: "ee",
    displayName: "-ee",
    allow: false,
    score: -10,
  },
  {
    id: "en",
    displayName: "-en",
    allow: false,
    score: -10,
  },
  {
    id: "er",
    displayName: "-er",
    allow: false,
    score: -10,
  },
  {
    id: "es",
    displayName: "-es",
    allow: false,
    score: -10,
  },
  {
    id: "et",
    displayName: "-et",
    allow: false,
    score: -10,
  },
  {
    id: "ge",
    displayName: "-ge",
    allow: false,
    score: -10,
  },
  {
    id: "go",
    displayName: "-go",
    allow: false,
    score: -10,
  },
  {
    id: "at",
    displayName: "-at",
    allow: false,
    score: -10,
  },
  {
    id: "ia",
    displayName: "-ia",
    allow: false,
    score: -10,
  },
  {
    id: "ic",
    displayName: "-ic",
    allow: false,
    score: -10,
  },
  {
    id: "ad",
    displayName: "-ad",
    allow: false,
    score: -10,
  },
  {
    id: "id",
    displayName: "-id",
    allow: false,
    score: -10,
  },
  {
    id: "ie",
    displayName: "-ie",
    allow: false,
    score: -10,
  },
  {
    id: "ve",
    displayName: "-ve",
    allow: false,
    score: -10,
  },
  {
    id: "il",
    displayName: "-il",
    allow: false,
    score: -10,
  },
  {
    id: "us",
    displayName: "-us",
    allow: false,
    score: -10,
  },
  {
    id: "in",
    displayName: "-in",
    allow: false,
    score: -10,
  },
  {
    id: "um",
    displayName: "-um",
    allow: false,
    score: -10,
  },
  {
    id: "co",
    displayName: "-co",
    allow: false,
    score: -10,
  },
  {
    id: "is",
    displayName: "-is",
    allow: false,
    score: -10,
  },
  {
    id: "te",
    displayName: "-te",
    allow: false,
    score: -10,
  },
  {
    id: "it",
    displayName: "-it",
    allow: false,
    score: -10,
  },
  {
    id: "ce",
    displayName: "-ce",
    allow: false,
    score: -10,
  },
  {
    id: "lo",
    displayName: "-lo",
    allow: false,
    score: -10,
  },
  {
    id: "ly",
    displayName: "-ly",
    allow: false,
    score: -10,
  },
  {
    id: "ma",
    displayName: "-ma",
    allow: false,
    score: -10,
  },
  {
    id: "me",
    displayName: "-me",
    allow: false,
    score: -10,
  },
  {
    id: "as",
    displayName: "-as",
    allow: false,
    score: -10,
  },
  {
    id: "na",
    displayName: "-na",
    allow: false,
    score: -10,
  },
  {
    id: "ol",
    displayName: "-ol",
    allow: false,
    score: -10,
  },
  {
    id: "om",
    displayName: "-om",
    allow: false,
    score: -10,
  },
  {
    id: "on",
    displayName: "-on",
    allow: false,
    score: -10,
  },
  {
    id: "or",
    displayName: "-or",
    allow: false,
    score: -10,
  },
  {
    id: "os",
    displayName: "-os",
    allow: false,
    score: -10,
  },
  {
    id: "ow",
    displayName: "-ow",
    allow: false,
    score: -10,
  },
  {
    id: "ra",
    displayName: "-ra",
    allow: false,
    score: -10,
  },
  {
    id: "re",
    displayName: "-re",
    allow: false,
    score: -10,
  },
  {
    id: "ry",
    displayName: "-ry",
    allow: false,
    score: -10,
  },
  {
    id: "an",
    displayName: "-an",
    allow: false,
    score: -10,
  },
  {
    id: "se",
    displayName: "-se",
    allow: false,
    score: -10,
  },
  {
    id: "sy",
    displayName: "-sy",
    allow: false,
    score: -10,
  },
  {
    id: "al",
    displayName: "-al",
    allow: false,
    score: -10,
  },
  {
    id: "ta",
    displayName: "-ta",
    allow: false,
    score: -10,
  },
  {
    id: "ala",
    displayName: "-ala",
    allow: false,
    score: -10,
    example: "gowala",
  },
  {
    id: "ite",
    displayName: "-ite",
    allow: false,
    score: -10,
  },
  {
    id: "ify",
    displayName: "-ify",
    allow: false,
    score: -10,
    example: "spotify",
  },
  {
    id: "ing",
    displayName: "-ing",
    allow: false,
    score: -10,
  },
  {
    id: "ity",
    displayName: "-ity",
    allow: false,
    score: -10,
  },
  {
    id: "ora",
    displayName: "-ora",
    allow: false,
    score: -10,
  },
  {
    id: "ous",
    displayName: "-ous",
    allow: false,
    score: -10,
  },
  {
    id: "oid",
    displayName: "-oid",
    allow: false,
    score: -10,
  },
  {
    id: "able",
    displayName: "-able",
    allow: false,
    score: -10,
  },
  {
    id: "inator",
    displayName: "-inator",
    allow: false,
    score: -10,
  },
  {
    id: "matic",
    displayName: "-matic",
    allow: false,
    score: -10,
  },
]

export default suffixes
