import React from 'react'

import { AppConsumer } from '../../../App'
import Filter from './Filter'
  import {
    FiltersWrapper
  } from '../../../styles/filter'

const Filters = () => (
  <AppConsumer>
    { ({state}) => (
      <FiltersWrapper>
        {
          state.filters
            .filter(f => f.show !== false)
            .map(f => <Filter key={f.id} filter={f} />)
        }
      </FiltersWrapper>
    )}
  </AppConsumer>
)

export default Filters
