// GET DOMAINS
// takes an array of keywords and returns an array of domain ideas as objects
// objects include the domain, as well as useful information about that results
// like it's score, which is a measure of quality ("dogs.com" > "mydogs.io" etc)
const updateResults = (prevState) => {

  const { keywords, filters } = prevState

  // Return null if there are no keywords
  // this different from "no results", which returns an empty array
  if (keywords.length < 1) {
    return null
  }

  // limit num of allowed keywords to prevent instane number of combos
  const keywordsLimited = keywords.slice(0, 3)

  // create an array of results with a single "result" object
  var results = [{
    keywords: keywordsLimited,
    score: 0,
  }]

  results = getResultsReordered(results, filters)
  results = getResultsWithWordAlternatives(results, filters)
  results = getResultsWithNoVowels(results, filters)
  results = getResultsWithLastVowelRemoved(results, filters)
  results = getResultsWithPrefixes(results, filters)
  results = getResultsWithSuffixes(results, filters)
  results = addHyphens(results, filters)
  results = renderDomainNames(results)
  results = getResultsWithTLDs(results, filters)
  results = removeDuplicateResults(results)

  return { results }
}


// RESULT FILTERS
// functions that take results and return more results
// TODO - use functional style for these filters
// ie return array for each tld and flatten with this:
// .reduce( (a, b) => a.concat(b),[])

// RE-ORDERING
// TODO - score un-reordered domain names higher
const getResultsReordered = (results, filters) => {

  // https://stackoverflow.com/questions/37579994/generate-permutations-of-javascript-array
  const getAllPermutationsOfArray=xs=>{let ret=[];for(let i=0;i<xs.length;i=i+1){let rest=getAllPermutationsOfArray(xs.slice(0,i).concat(xs.slice(i+1)));if(!rest.length){ret.push([xs[i]])}else{for(let j=0;j<rest.length;j=j+1){ret.push([xs[i]].concat(rest[j]))}}}return ret};

  const filter = filters.find(f => f.id === 'special')

  if (!filter.items.find(i => i.id === 'reorder').allow) {
    return results
  }

  const newResults = []
  results.forEach(result => {
    getAllPermutationsOfArray(result.keywords).forEach(ordering => {
      let reorderedResult = {...result}
      reorderedResult.keywords = ordering
      newResults.push(reorderedResult)
    })
  })
  return newResults
}

// WORD ALTERNATIVES
const getResultsWithWordAlternatives = (results, filters) => {
  const wordFilters = filters.filter(f => f.type === 'word')
  return getAlternates(results, wordFilters)
}

const getAlternates = (results, filters) => {

  // if there are no more filters left
  if (filters.length === 0) {
    return results
  }

  // otherwise, generate new results based on the first filter only
  const currentFilter = filters[0]
  const remainingFilters = filters.slice(1)
  const word = currentFilter.word
  const alternatives = currentFilter.items.filter(i => i.allow)
  const newResults = []

  results.forEach(result => {
    // no alternatives
    newResults.push( JSON.parse(JSON.stringify(result)) )

    alternatives.forEach(alternate => {
      // using object assign was skill keeping the children of the object
      // as a REFERENCE, not a copy,
      // which meant the results w/o a word replacement were getting overwritten
      // use this method to make a deep copy instead
      // https://medium.com/@tkssharma/objects-in-javascript-object-assign-deep-copy-64106c9aefab
      let alternateResult = JSON.parse(JSON.stringify(result))
      const indexToReplace = alternateResult.keywords.indexOf(word)
      alternateResult.keywords[indexToReplace] = alternate.id
      // TODO - score non-alternate domain names higher
      newResults.push(alternateResult)
    })
  })

  // call this function recursively with the new results and the remaining filters
  return getAlternates(newResults, remainingFilters)
}

// NO VOWELS
const getResultsWithNoVowels = (results, filters) => {

  const filter = filters.find(f => f.id === 'special')

  if (!filter.items.find(i => i.id === 'removeallvowels').allow) {
    return results
  }

  const newResults = []
  results.forEach(result => {
    newResults.push({...result})
    result.keywords = result.keywords.map(word => word.replace(/[aeiou]/i,''))
    newResults.push({...result})
  })

  return newResults
}


// REMOVE LAST VOWEL
const getResultsWithLastVowelRemoved = (results, filters) => {

  const filter = filters.find(f => f.id === 'special')

  if (!filter.items.find(i => i.id === 'removelastvowel').allow) {
    return results
  }

  const newResults = []
  results.forEach(result => {
    newResults.push({...result})
    var numKeywords = result.keywords.length
    result.keywords = result.keywords.map( (word, index) => {

      // don't clip words that aren't the last word in a result
      if (index !== numKeywords - 1) {
        return word
      }

      // remove last vowel
      // feels like there's a better way to do this, but whatever (regex is hard)
      var isVowelRegex = /[aeiou]/i
      var wordArray = word.split('')
      wordArray.reverse()
      var firstVowelPosition = wordArray.findIndex(char => char.match(isVowelRegex))
      if (firstVowelPosition > -1) {
        wordArray.splice(firstVowelPosition, 1)
      }
      wordArray.reverse()
      var lastVowelRemoved = wordArray.join('')

      return lastVowelRemoved
    })
    newResults.push({...result})
  })
  
  return newResults
}

// PREFIXES
const getResultsWithPrefixes = (results, filters) => {
  const filter = filters.find(f => f.id === 'prefix')
  const prefixes = filter.items.filter(i => i.allow)
  const newResults = []
  results.forEach(result => {
    // no prefix
    newResults.push({...result})
    // each available prefix
    prefixes.forEach(prefix => {
      let prefixedResult = {...result}
      prefixedResult.prefix = prefix.id
      prefixedResult.score = prefix.score ? prefixedResult.score + prefix.score : prefixedResult.score
      newResults.push(prefixedResult)
    })
  })
  return newResults
}

// SUFFIXES
const getResultsWithSuffixes = (results, filters) => {
  const filter = filters.find(f => f.id === 'suffix')
  const suffixes = filter.items.filter(i => i.allow)
  const newResults = []
  results.forEach(result => {
    // no prefix
    newResults.push({...result})
    // each available suffix
    suffixes.forEach(suffix => {
      let suffixedResult = {...result}
      suffixedResult.suffix = suffix.id
      suffixedResult.score = suffix.score ? suffixedResult.score + suffix.score : suffixedResult.score
      newResults.push(suffixedResult)
    })
  })
  return newResults
}

// ADD HYPHENS
const addHyphens = (results, filters) => {
  const filter = filters.find(f => f.id === 'special')

  if (!filter.items.find(i => i.id === 'addhyphens').allow) {
    return results
  }

  const newResults = []
  results.forEach(result => {
    newResults.push({...result})
    const newKeywords = []
    result.keywords.forEach((keyword, index) => {
      newKeywords.push(keyword)

      // no hyphen after the last word
      if (index < result.keywords.length - 1) {
        newKeywords.push('-')
      }
    })

    // add hyphens at beginning of the keywords, anticipating prefixes
    if (result.prefix) {
      newKeywords.unshift('-')
    }

    newResults.push({...result, keywords: newKeywords})
  })

  return newResults
}

// RENDER DOMAIN NAMES
const renderDomainNames = results => {
  return results.map(r => {
    const prefix = r.prefix ? r.prefix : ''
    const suffix = r.suffix ? r.suffix : ''
    r.domainName = prefix + r.keywords.join('') + suffix
    return r
  })
}

// TLDs
const getResultsWithTLDs = (results, filters) => {
  const filter = filters.find(f => f.id === 'tld')
  const tlds = filter.items.filter(i => i.allow)
  const newResults = []
  results.forEach(result => {
    tlds.forEach(tld => {
      let tldResult = {...result}
      tldResult.tld = tld.id
      tldResult.domain = tldResult.domainName+'.'+tld.id
      tldResult.score = tld.score ? tldResult.score + tld.score : tldResult.score
      newResults.push(tldResult)
    })
  })
  return newResults
}

// REMOVE DUPES
// ensure there are no duplicate domains
// this is not only odd for users, but breaks react ids on results
// and it can happen, for ex: both prefex and suffix filters add a "no prefix" or "no suffix" result
// https://ilikekillnerds.com/2016/05/removing-duplicate-objects-array-property-name-javascript/
const removeDuplicateResults = (results) => {
  return results.filter( (result, index, results) => {
    const domains = results.map(r => r.domain)
    return domains.indexOf(result.domain) === index
  })
}

export default updateResults
