import React from 'react'

import {
  OutboundLink,
  FooterWrapper,
} from '../../styles/components'

const Footer = () => (
  <FooterWrapper>

    <OutboundLink
      to="https://sampl.typeform.com/to/rlGjwK"
      eventLabel="Typeform feedback form"
      target="_blank"
      rel="noopener noreferrer"
    >
      feedback
    </OutboundLink>

    {' · '}
    
    <OutboundLink
      to="http://sampiercelolla.com/subscribe"
      eventLabel="Mailchimp signup form"
      target="_blank"
      rel="noopener noreferrer"
      >
      get updates
    </OutboundLink>

    {' · '}

    <OutboundLink
      to="http://twitter.com/sampleite"
      eventLabel="Sam twitter"
      target="_blank"
      rel="noopener noreferrer"
    >
      twitter
    </OutboundLink>

    <br/>

    <OutboundLink
      to="https://directedworks.com/"
      eventLabel="Directed works site"
      target="_blank"
      rel="noopener noreferrer"
    >
      &copy; {(new Date()).getFullYear()}
      {' '}
      Directed Works
    </OutboundLink>

  </FooterWrapper>
)

export default Footer
