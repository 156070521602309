// from the Domainr API
// https://domainr.build/docs/status

const apiCodes = {
  unknown: {
    apiStatusCode: 'unknown',
    apiStatusMessage: 'Unknown status, usually resulting from an error or misconfiguration.',
    available: "unavailable",
    show: true,
    message: 'Not available',
  },
  undelegated: {
    apiStatusCode: 'undelegated',
    apiStatusMessage: 'The domain is not present in DNS.',
    available: "unavailable",
    show: true,
    message: 'Not available',
  },
  inactive: {
    apiStatusCode: 'inactive',
    apiStatusMessage: 'Available for new registration.',
    available: "available",
    show: true,
    message: 'Available!',
  },
  pending: {
    apiStatusCode: 'pending',
    apiStatusMessage: 'TLD not yet in the root zone file.',
    available: "maybe",
    show: true,
    message: 'TLD not yet in the root zone file',
  },
  unregistrable: {
    apiStatusCode: 'unregistrable',
    apiStatusMessage: 'No registrars offer this domain for sale.',
    available: "unavailable",
    show: true,
    message: 'Not available',
  },
  disallowed: {
    apiStatusCode: 'disallowed',
    apiStatusMessage: 'Disallowed by the registry, ICANN, or other (wrong script, etc.).',
    available: "unavailable",
    show: true,
    message: 'Not available',
  },
  claimed: {
    apiStatusCode: "claimed",
    apiStatusMessage: "Claimed or reserved by some party (not available for new registration)",
    available: "unavailable",
    show: true,
    message: 'Not available',
  },
  reserved: {
    apiStatusCode: "reserved",
    apiStatusMessage: "Explicitly reserved by ICANN, the registry, or another party",
    available: "unavailable",
    show: true,
    message: 'Not available',
  },
  dpml: {
    apiStatusCode: "dpml",
    apiStatusMessage: "Domains Protected Marks List, reserved for trademark holders.",
    available: "unavailable",
    show: true,
    message: 'Not available',
  },
  invalid: {
    apiStatusCode: "invalid",
    apiStatusMessage: "Technically invalid, e.g. too long or too short.",
    available: "unavailable",
    show: true,
    message: 'Not available',
  },
  active: {
    apiStatusCode: "active",
    apiStatusMessage: "Registered, but possibly available via the aftermarket.",
    available: "unavailable",
    show: true,
    message: 'Taken',
  },
  parked: {
    apiStatusCode: "parked",
    apiStatusMessage: "Active and parked.",
    available: "maybe",
    show: true,
    message: 'Parked',
  },
  marketed: {
    apiStatusCode: "marketed",
    apiStatusMessage: "Explicitly marketed as for sale.",
    available: "maybe",
    show: true,
    message: 'For sale',
  },
  expiring: {
    apiStatusCode: "expiring",
    apiStatusMessage: "An expiring domain. E.g. from the SnapNames inventory.",
    available: "unavailable",
    show: true,
    message: 'Expiring soon',
  },
  deleting: {
    apiStatusCode: "deleting",
    apiStatusMessage: "A deleting domain. E.g. from the SnapNames inventory.",
    available: "available",
    show: true,
    message: 'Deleting',
  },
  priced: {
    apiStatusCode: "priced",
    apiStatusMessage: "An aftermarket domain with an explicit price. E.g. from the BuyDomains inventory.",
    available: "maybe",
    show: true,
    message: 'For sale',
  },
  transferable: {
    apiStatusCode: "transferable",
    apiStatusMessage: "An aftermarket domain available for fast-transfer. E.g. from the Afternic inventory.",
    available: "maybe",
    show: true,
    message: 'For sale',
  },
  premium: {
    apiStatusCode: "premium",
    apiStatusMessage: "Premium domain name for sale by the registry.",
    available: "maybe",
    show: true,
    message: 'Available (but premium)',
  },
  suffix: {
    apiStatusCode: "suffix",
    apiStatusMessage: "A public suffix according to publicsuffix.org.",
    available: "unavailable",
    show: false,
    message: 'Not available',
  },
  registrar: {
    apiStatusCode: "registrar",
    apiStatusMessage: "A domain controlled by a registrar.",
    available: "unavailable",
    show: false,
    message: 'Not available',
  },
  zone: {
    apiStatusCode: "zone",
    apiStatusMessage: "A zone (domain extension) in the Domainr database.",
    available: "unavailable",
    show: false,
    message: 'Not available',
  },
  tld: {
    apiStatusCode: "tld",
    apiStatusMessage: "Top-level domain.",
    available: "unavailable",
    show: false,
    message: 'Not available',
  },
}

export default apiCodes
