// takes an array of keywords as strings, NOT a single string of keywords
const updateKeywords = (prevState, keywords) => {
  const filters = prevState.filters.slice()

  // add a filter for each keyword
  keywords.forEach( keyword => {
    if (!filters.find(f => f.word === keyword)) {
      const newFilter = {
        id: `word-${keyword}`,
        type: 'word',
        show: 'true',
        word: keyword,
        displayName: `Words like "${keyword}"`,
        items: [],
      }
      // push to beginning
      filters.unshift(newFilter)
    }
  })

  // hide old word filters
  filters.filter(f => f.word).forEach( (filter, index) => {
    filters[index].show = keywords.indexOf(filter.word) >= 0 ? true : false
  })

  return {
    filters,
    keywords,
  }
}

export default updateKeywords
