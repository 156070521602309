const tlds = [
  {
    id: "com",
    displayName: ".com",
    allow: true,
    example: "ex: google.com",
    score: 30,
  },
  {
    id: "org",
    displayName: ".org",
    allow: false,
    example: "ex: wikipedia.org",
    score: 10,
  },
  {
    id: "net",
    displayName: ".net",
    allow: false,
    example: "ex: slideshare.net",
    score: 0,
  },
  {
    id: "io",
    displayName: ".io",
    allow: false,
    example: "ex: sentry.io",
    score: 10,
  },
  {
    id: "co",
    displayName: ".co",
    allow: false,
    example: "ex: angel.co",
    score: 10,
  },
  {
    id: "me",
    displayName: ".me",
    allow: false,
    example: "ex: unroll.me",
    score: 10,
  },
  {
    id: "tv",
    displayName: ".tv",
    allow: false,
    score: 10,
  },
  {
    id: "xyz",
    displayName: ".xyz",
    allow: false,
    score: 10,
  },
  {
    id: "fm",
    displayName: ".fm",
    allow: false,
    score: 10,
  },
  {
    id: "domains",
    displayName: ".domains",
    allow: false,
    example: "dope.domains",
    score: 0,
  },
  {
    id: "it",
    displayName: ".it",
    allow: false,
    score: 0,
  },
  {
    id: "in",
    displayName: ".in",
    allow: false,
    score: 0,
  },
  {
    id: "us",
    displayName: ".us",
    allow: false,
    score: 0,
  },
  {
    id: "my",
    displayName: ".my",
    allow: false,
    score: 0,
  },
  {
    id: "biz",
    displayName: ".biz",
    allow: false,
    score: 0,
  },
  {
    id: "cc",
    displayName: ".cc",
    allow: false,
    score: 0,
  },
  {
    id: "club",
    displayName: ".club",
    allow: false,
    score: 0,
  },
  {
    id: "info",
    displayName: ".info",
    allow: false,
    score: 0,
  },
  {
    id: "edu",
    displayName: ".edu",
    allow: false,
    score: 0,
  },
  {
    id: "pro",
    displayName: ".pro",
    allow: false,
    score: 0,
  },
  {
    id: "online",
    displayName: ".online",
    allow: false,
    score: 0,
  },
  {
    id: "top",
    displayName: ".top",
    allow: false,
    score: 0,
  },
  {
    id: "site",
    displayName: ".site",
    allow: false,
    score: 0,
  },
]

export default tlds
