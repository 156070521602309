import React from 'react'

import Result from './Result'
import { AppConsumer } from '../../../App'
import {
  ResultsWrapper,
  ShowMoreResults,
  NoMoreResults,
} from '../../../styles/result'

const Results = () => (
  <AppConsumer>
    { ({actions, state}) => (
      <div>
        <ResultsWrapper>
          {state.results
            .sort( (a,b) => a.score < b.score )
            .slice(0, state.resultsToShow)
            .map(r => <Result key={r.domain} result={r} /> )
          }
        </ResultsWrapper>
        {
          state.results.length > state.resultsToShow ?
          <ShowMoreResults onClick={actions.onShowMore}>more results...</ShowMoreResults> :
          <NoMoreResults>no more results</NoMoreResults>
        }
      </div>
    )}
  </AppConsumer>
)

export default Results
