import styled from 'styled-components'

const ResultsWrapper = styled.div`
	display: grid;
	grid-row-gap: 1rem;
	grid-column-gap: 1rem;
	grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));  
`

const ShowMoreResults = styled.div`
  cursor: pointer;
  background: var(--red97);
  color: var(--textSecondary);
  font-size: var(--size-m);
  width: 100%;
  margin: 1rem 0;
  padding: .5rem 1rem;
  border-radius: var(--borderRadius);
  text-align: center;
  opacity: .9;
  
  &:hover {
    opacity: 1;
  }
`
const NoMoreResults = styled.div`
  color: var(--gray60);
  text-align: center;
  padding: 1.5rem;
`

const ResultWrapper = styled.div`
  padding: .5rem 1rem;
  border-radius: 4px;
  cursor: pointer;

  transition: all .2s ease;

  &:hover,
  &:active {
    transform: scale(1.01);
  }

  &.waiting {
    color: var(--gray60);
    background: var(--gray97);
  }
  &.available {
    color: var(--green);
    background: var(--greenLight);
  }
  &.maybe {
    color: var(--gray40);
    background: var(--gray97);
  }
  &.unavailable {
    color: var(--gray60);
    background: var(--gray97);
  }
  &.error {
    color: var(--red);
    background: var(--redLight);
  }

  @media (min-device-width: 600px) {
    min-width: 200px;
  }
  @media (max-device-width: 599px) {
    width: 100%;
  }
`
const ResultDomain = styled.div`
  font-size: var(--size-l);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ResultStatus = styled.div`
  font-size: var(--size-m);
  opacity: .5;
`

export {
  ResultsWrapper,
  ShowMoreResults,
  NoMoreResults,

  ResultWrapper,
  ResultDomain,
  ResultStatus,
}