import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import styled from 'styled-components'

const A = styled.a`
  color: var(--textPrimary);
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid var(--red80);

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: var(--textPrimary);
    border-bottom: 1px solid var(--red70);
  }
`
const AppLink = A.withComponent(Link)
const OutboundLink = A.withComponent(ReactGA.OutboundLink)

const Button = styled.button`
  font-size: var(--size-l);
  border: 2px solid transparent;
  border-radius: var(--borderRadius);
  outline: none;
  padding: .5rem 1rem;
  cursor: pointer;
  background: var(--textPrimary);
  color: white;
  text-decoration: none;
  text-align: center;
  transition: all .1s ease;
  box-shadow: 0 10px 10px -5px hsla(0, 100%, 50%, 0.2);

  &:hover {
    box-shadow: 0 6px 22px -10px var(--red70);
    transform: translateY(-1px);
  }
  &:focus {
    box-shadow: 0 6px 22px -10px var(--red70);
    transform: translateY(-1px);
  }
  &:active {
    box-shadow: 0 4px 18px -10px var(--red70);
    transform: translateY(0);
  }
`
const CloseX = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--textPrimary);
  background: var(--red97);
  border-radius: 50%;
  opacity: .9;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    opacity: 1;
    background: var(--red95);
  }
`
const Input = styled.input`
  font-size: var(--size-l);
  color: var(--textPrimary);
  border: none;
  background: var(--white);
  border-radius: var(--borderRadius);
  outline: none;
  margin: 0 0 1rem;
  padding: .5rem .75rem;
  width: 100%;
  box-shadow: 0 4px 18px -10px var(--red70);
  opacity: .9;
  transition: all .1s ease;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    /* transform: scale(1.01); */
  }
  &:hover {
    box-shadow: 0 6px 22px -10px var(--red70);
  }
  &:focus {
    box-shadow: 0 6px 22px -10px var(--red70);
  }
  &:active {
    /* box-shadow: 0 6px 22px -10px var(--red70); */
  }
`
const FooterWrapper = styled.div`
  align-self: end;
  color: var(--red80);
  font-size: 0.8rem;

  a {
    font-size: 0.8rem !important;
    color: var(--textSecondary) !important;
    text-decoration: none;
    border: none;

    &:hover,
    &:active,
    &:focus {
      border: none;
      text-decoration: underline;
    }

  }
`

export {
  A,
  Button,
  CloseX,
  Input,
  AppLink,
  OutboundLink,
  FooterWrapper,
}
