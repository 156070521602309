import tlds from './tlds'
import prefixes from './prefixes'
import suffixes from './suffixes'
import specialFilters from './specialFilters'

const defaultFilters = [
  {
    id: 'tld',
    displayName: 'TLDs',
    items: tlds,
  },
  {
    id: 'prefix',
    displayName: 'Prefixes',
    items: prefixes,
  },
  {
    id: 'suffix',
    displayName: 'Suffixes',
    items: suffixes,
  },
  {
    id: 'special',
    displayName: 'Misc',
    items: specialFilters,
  },
]

export default defaultFilters
