import React from 'react'

import FilterItem from './FilterItem'

const FilterItems = ({filter}) => (
  <div>
    {filter.items.map(i => (
      <FilterItem
        key={i.id}
        filter={filter}
        item={i}
      />
    ))}
  </div>
)

export default FilterItems