// Higher-Order Component for Google Analytics
// tracks pageviews
// https://github.com/react-ga/react-ga/issues/122#issuecomment-299692833

import React from 'react'
import ReactGA from 'react-ga'

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)

const withTracker = WrappedComponent => {
  return class extends React.Component {
    componentDidMount() {
      trackPage(this.props.location.pathname)
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

}

const trackPage = page => {
  ReactGA.set({page})
  ReactGA.pageview(page)
}

export default withTracker
