const prefixes = [
  {
    id: "my",
    displayName: "my-",
    allow: false,
    score: -10,
  },
  {
    id: "the",
    displayName: "the-",
    allow: false,
    example: "thefacebook",
    score: -10,
  },
  {
    id: "go",
    displayName: "go-",
    allow: false,
    score: -10,
  },
  {
    id: "try",
    displayName: "try-",
    allow: false,
    score: -10,
  },
  {
    id: "get",
    displayName: "get-",
    allow: false,
    example: "getdropbox",
    score: -10,
  },
  {
    id: "shop",
    displayName: "shop-",
    allow: false,
    score: -10,
  },
  {
    id: "super",
    displayName: "super-",
    allow: false,
    score: -10,
  },
  {
    id: "all",
    displayName: "all-",
    allow: false,
    score: -10,
  },
  {
    id: "meta",
    displayName: "meta-",
    allow: false,
    score: -10,
  },
  {
    id: "big",
    displayName: "big-",
    allow: false,
    score: -10,
  },
  {
    id: "live",
    displayName: "live-",
    allow: false,
    score: -10,
  },
  {
    id: "top",
    displayName: "top-",
    allow: false,
    score: -10,
  },
  {
    id: "your",
    displayName: "your-",
    allow: false,
    score: -10,
  },
]

export default prefixes
