import React from 'react'

const RandomTagline = () => {
  const taglines = [
    <span>{"they're good domains, brent"} <span role="img" aria-label="dog">🐕</span> </span>,
    <span>duddddeee those domains are sicccckkkkkk <span role="img" aria-label="surfer">🏄‍</span> </span>,
    <span>the friggin{"'"} dopest-ever domain name finder, chyeah <span role="img" aria-label="rock-hands">🤘</span> </span>,
    <span>TBH this is the best name generator of all time <span role="img" aria-label="microphone">🎤</span> </span>,
    <span>YOU get a domain, and YOU get a domain, and YOU get a domain <span role="img" aria-label="bee">🐝</span> </span>,
  ]

  const randomTagline = taglines[Math.floor(Math.random() * taglines.length)]

  return <p>{randomTagline}</p>
}

export default RandomTagline
