import styled from 'styled-components'
import { Link } from 'react-router-dom'

const HomePage = styled.div`
  text-align: center;
  padding: 2rem 1rem 2rem;
  display: grid;
  height: 100%;

  & > div {
    z-index: 10;
  }
`

const LogoWrapper = styled(Link)`
  display: block;
  position: relative;
  margin: 0 auto 1rem;
  width: 100%;
  background: url('/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
  opacity: .9; /* soften colors */

  @media (min-device-width: 600px) {
    height: 180px;
  }
  @media (max-device-width: 599px) {
    height: 120px;
  }
`

const SearchForm = styled.form`
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  margin: 2.5rem auto .5rem;
  max-width: 500px;
  
  @media (min-device-width: 600px) {
    grid-template-columns: auto max-content;
  }
  @media (max-device-width: 599px) {
    grid-template-columns: auto;
  }

  input {
    margin: 0;
  }
`
const Well = styled.div`
  margin: 2rem auto;
  text-align: left;
  max-width: 500px;
  padding: 1rem;
  background: var(--red95);
`
const Video = styled.video`
  background: var(--purple);
  margin: 0 auto;
  width: 26rem;
  height: 16rem;
  max-width: 100%;
`
const Gifs = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  
  img {
    position: absolute;
  }
`

export {
  HomePage,
  LogoWrapper,
  SearchForm,
  Well,
  Video,
  Gifs
}