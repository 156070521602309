import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './home'
import Search from './search'
import Error from './Error'

const Routes = () => (
  <Switch>
    <Route path="/search/:query" component={Search} />
    <Route path="/search" component={Search} />
    <Route exact path="/" render={() => <Home radMode={false} /> } />
    <Route component={Error} />
  </Switch>
)

export default Routes
