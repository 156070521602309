import React from 'react'
import ReactGA from 'react-ga'
import Drawer from 'react-drag-drawer'
import { Popover } from 'antd'
import 'antd/dist/antd.css'

import withIsMobile from '../../../util/withIsMobile'
import {
  CloseX,
} from '../../../styles/components'
import {
  ModalWindow,
  ModalTitle,
  ModalBody,
} from '../../../styles/modal'
import ResultPopup from './ResultPopup'
import {
  ResultWrapper,
  ResultDomain,
  ResultStatus,
} from '../../../styles/result'

class Result extends React.Component {

  state = {
    open: false,
  }

  openResult = () => {
    ReactGA.event({
      category: 'User',
      action: 'Click result',
    })

    this.setState({
      open: true,
    })
  }

  closeResult = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    let className = ''
    let status = ''
    let onClick = null

    if (this.props.result.error) {
      className = 'error'
      status = 'error loading domain'
      onClick = null
    } else if (this.props.result.isLoading) {
      className = 'waiting'
      status = 'loading...'
      onClick = null
    } else if (this.props.result.status) {
      className = this.props.result.status.available
      status = this.props.result.status.message
      onClick = this.openResult
    } else {
      className = '???'
      status = '???'
      onClick = this.openResult
    }
    
    const resultComponent = <ResultWrapper onClick={onClick} className={className}>
      <ResultDomain>{this.props.result.domain}</ResultDomain>
      <ResultStatus>{status}</ResultStatus>        
    </ResultWrapper>
    
    if (this.props.isMobile) {
      return <div>

        {resultComponent}

        <Drawer open={this.state.open} onRequestClose={this.closeResult}>
          <ModalWindow>
            <CloseX onClick={this.closeResult}>&times;</CloseX>
            <ModalTitle>{this.props.result.domain}</ModalTitle>
            <ModalBody>
              <ResultPopup result={this.props.result} />
            </ModalBody>
          </ModalWindow>
        </Drawer>
      </div>
    }

    return <Popover content={<ResultPopup result={this.props.result} />} trigger="click">
      {resultComponent}
    </Popover>
  }
}

export default withIsMobile(Result)
