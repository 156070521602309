import { injectGlobal } from 'styled-components'

// GLOBAL STYLES
injectGlobal`

  :root {

    /* FONT SIZES */
    --size-xl: 1.5rem;
    --size-l:  1.2rem;
    --size-m:  1rem;
    --size-s:  .88rem;

    /* COLORS */
    --white:        hsl(  0,   0%, 100%);
    --red:          hsl(  0,  80%,  60%);
    --yellow:       hsl( 65, 100%,  80%);
    --green:        hsl(135,  70%,  45%);
    --blue:         hsl(200, 100%,  50%);
    --purple:       hsl(260,  80%,  60%);
    --pink:         hsl(340,  80%,  60%);

    --redLight:     hsla(  0,  80%,  60%, .1);
    --yellowLight:  hsla( 65, 100%,  80%, .1);
    --greenLight:   hsla(135,  70%,  45%, .1);
    --blueLight:    hsla(200, 100%,  50%, .1);
    --purpleLight:  hsla(260,  80%,  60%, .1);
    --pinkLight:    hsla(340,  80%,  60%, .1);

    --gray40:       hsl(200,  40%,  40%);
    --gray60:       hsl(200,  30%,  60%);
    --gray80:       hsl(200,  25%,  80%);
    --gray90:       hsl(200,  20%,  90%);
    --gray97:       hsl(200,  10%,  97%);
    
    --red60:        hsl( -5,  90%,  60%);
    --red70:        hsl(  0,  80%,  70%);
    --red80:        hsl( 10,  80%,  80%);
    --red90:        hsl( 10, 100%,  90%);
    --red95:        hsl( 10, 100%,  95%);
    --red97:        hsl( 10, 100%,  97%);

    /* Semantic colors */

    --textPrimary:  var(--red60);
    --textSecondary:  var(--red70);
    --shadow:  hsla(0,  100%,  50%, 0.1);

    /* OTHER */
    --borderRadius: 6px;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: var(--red90);
    /* background-image: url("/gifs/astro.gif"); */
    /* background: linear-gradient( hsl(0,100%,95%), hsl(20,100%,95%) ); */
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    line-height: 1.5;
    font-size: var(--size-m);
    color: hsl(210, 30%, 40%);
    color: var(--textPrimary);
  }

  /* TEXT */

  p {
    margin: 0 0 1rem;
  }

  ul {
    margin: 0 0 -.5rem;
  }

  li {
    margin: 0 0 .5rem;
  }

  input[type="checkbox"]:disabled {
    opacity: .5;
  }

  ::placeholder {
    color: var(--red80);
  }

  /*  FADE TRANSITION */

  .fade-enter {
    opacity: 0;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 200ms ease-in;
  }

  .fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  .fade-exit.fade-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: all 200ms ease;
  }
`
