const specialFilters = [
  {
    id: "reorder",
    displayName: "Reorder words",
    allow: false,
    example: '"winter cafe" → "cafe winter"',
    score: -10,
  },
  {
    id: "removelastvowel",
    displayName: "Remove last vowel",
    allow: false,
    example: '"flicker" → "flickr"',
    score: -10,
  },
  {
    id: "removeallvowels",
    displayName: "Remove all vowels",
    allow: false,
    example: '"typewriter" → "typwrtr"',
    score: -10,
  },
  {
    id: "addhyphens",
    displayName: "Add hyphens between words",
    allow: false,
    example: '"myapp" → "my-app"',
    score: -10,
  },
]

export default specialFilters
