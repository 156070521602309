import React from 'react'
import fetchJsonp from 'fetch-jsonp'

import apiCodes from './util/apiCodes'
import defaultFilters from './util/defaultFilters'
import Routes from './views/Routes'

// actions
import toggleFilter from './actions/toggleFilter'
import updateFilterItem from './actions/updateFilterItem'
import updateKeywords from './actions/updateKeywords'
import updateResults from './actions/updateResults'

// manage app state using React Context
const Context = React.createContext()
const AppProvider = Context.Provider
const AppConsumer = Context.Consumer


const NUM_RESULTS_PER_PAGE = 12

class App extends React.Component {
  
  state = {
    inputText: '',
    keywords: [],
    filters: defaultFilters,
    results: null,
    numFilterItemsSelected: 1,
    resultsToShow: NUM_RESULTS_PER_PAGE,
  }

  onKeywordType = text => {
    this.setState({
      inputText: text ? text.toLowerCase() : ''
    })
  }

  toggleFilter = update => {
    this.setState( prevState => toggleFilter(prevState, update))
  }

  updateFilterItem = update => {
    this.setState( prevState => updateFilterItem(prevState, update), this.updateResults)
  }

  updateKeywords = keywords => {
    this.setState( prevState => updateKeywords(prevState, keywords), () => {
      this.setState({resultsToShow: NUM_RESULTS_PER_PAGE}, () => {
        this.updateResults()
        this.getSuggestionsForWordFilters()
      })
    })
  }

  updateResults = () => {
    this.setState( prevState => updateResults(prevState), this.getAvailabilityForResults)
  }

  getSuggestionsForWordFilters = () => {
    this.state.filters.filter(f => f.word).forEach( filter => {
      
      // only words that don't already have suggestions
      if (filter.items.length !== 0 || filter.loading) {
        return
      }
      
      // set filter "loading" to true
      this.setState(prevState => {
        let filters = prevState.filters.slice()
        const index = filters.findIndex(f => f.word === filter.word)
        filters[index].loading = true
        return { filters }
      })
      
      fetch('/api/similar?word='+filter.word)
      .then(res => res.json())
      .then(suggestions => {
          const filterItems = suggestions.map(s => {
            return {
              id: s.word,
              displayName: s.word,
              allow: false,
              score: s.score/10000,
            }
          })
    
          this.setState(prevState => {
            let filters = prevState.filters.slice()
            const index = filters.findIndex(f => f.word === filter.word)
            filters[index].loading = false
            filters[index].items = filterItems
            return { filters }
          })    
        })
        .catch(error => {
          console.error('Could not get similar words for '+filter.word, error)
          this.setState(prevState => {
            let filters = prevState.filters.slice()
            const index = filters.findIndex(f => f.word === filter.word)
            filters[index].loading = false
            return { filters }
          })    
        })
    })
  }

  getAvailabilityForResults = () => {
    if (!this.state.results) {
      return
    }
    
    this.state.results
    .slice(0, this.state.resultsToShow)
    .filter(r => !r.isLoading && !r.isLoaded)
    .forEach( result => {

      // set result "loading" to true
      this.setState(prevState => {
        let results = prevState.results.slice()
        const index = results.findIndex(r => r.domain === result.domain)
        results[index].isLoading = true
        return { results }
      })
  
      const url = `https://domainr.p.mashape.com/v2/status?mashape-key=${process.env.REACT_APP_MASHAPE_KEY}&domain=${result.domain}`
  
      fetchJsonp(url).then( response => response.json() ).then( json => {
        const summary = json.status[0].summary
        this.setState(prevState => {
          let results = prevState.results.slice()
          const index = results.findIndex(r => r.domain === result.domain)
          if (!results[index]) {
            console.warn('got weird')
            return
          }
          results[index].isLoaded = true
          results[index].isLoading = false
          results[index].error = false
          results[index].apiSummary = summary
          results[index].status = apiCodes[summary]
          return { results }
        })
      }).catch( error => {
        console.error('Getting domain status failed', error)
        this.setState(prevState => {
          let results = prevState.results.slice()
          const index = results.findIndex(r => r.domain === result.domain)
          if (!results[index]) {
            console.warn('got weird')
            return
          }
          results[index].isLoaded = true
          results[index].isLoading = false
          results[index].error = error
          results[index].apiSummary = null
          return { results }
        })
      })
  
    })
  }

  onShowMore = () => {
    console.log('show more')
    this.setState(prevState => ({
      resultsToShow: prevState.resultsToShow + NUM_RESULTS_PER_PAGE,
    }), () => {
      this.getAvailabilityForResults()
    })
  }
  
  render() {
    return <AppProvider value={{
        state: this.state,
        actions: {
          onKeywordType: this.onKeywordType,
          toggleFilter: this.toggleFilter,
          updateFilterItem: this.updateFilterItem,
          updateKeywords: this.updateKeywords,
          updateResults: this.updateResults,
          onShowMore: this.onShowMore,
        }
      }}>
      <Routes />
    </AppProvider>
  }
}

export {
  App,
  AppProvider,
  AppConsumer,
}