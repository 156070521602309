import React from 'react'

import withIsMobile from '../../util/withIsMobile'
import RandomTagline from './RandomTagline'
import ExplosionClicker from './ExplosionClicker'
import Footer from './Footer'
import {
  AppLink,
  Input,
  Button,
} from '../../styles/components'
import {
  HomePage,
  LogoWrapper,
  // Well,
  // Video,
  SearchForm,
  Gifs,
} from '../../styles/home'

const handleSubmit = (event) => {
  event.preventDefault()
  var searchTerms = event.target.elements[0].value
  window.location.replace(`/search/${searchTerms}`)
}

const Home = ({isMobile, radMode}) => (
  <HomePage>

    {
      radMode &&
      <Gifs>
        <img src="/gifs/Butterfly.gif" alt="butterfly" style={{right: '10%', top: '10%'}} />
        <img src="/gifs/dovec.gif" alt="dove" style={{left: '10%', top: '10%'}} />
        <img src="/gifs/dancingbaby1.gif" alt="baby" style={{bottom: '0', left: '20%'}} />
        <img src="/gifs/dancingalien.gif" alt="baby" style={{bottom: '0', left: '28%'}} />
        <img src="/gifs/StrollingRobotANI.gif" alt="baby" style={{bottom: '0', left: '88%'}} />
        <img src="/gifs/kuri34.gif" alt="pyramids" style={{bottom: '20%', left: '20%'}} />
        <img src="/gifs/YingYang.gif" alt="pyramids" style={{bottom: '20%', left: '80%'}} />
        <img src="/gifs/dollarspindownd.gif" alt="pyramids" style={{bottom: '30%', left: '30%'}} />
        <img src="/gifs/earth3d.gif" alt="pyramids" style={{bottom: '50%', left: '30%'}} />
        <img src="/gifs/eyeball-1.gif" alt="pyramids" style={{bottom: '70%', left: '30%'}} />
        <img src="/gifs/record.gif" alt="record" style={{bottom: '20%', right: '20%'}} />
        <img src="/gifs/smoking-skull.gif" alt="skull" style={{left: '50%', bottom: '25%', transform: 'translateX(-50%)'}} />
        <img src="/gifs/sun_glasses_lg_clr.gif" alt="" style={{top: '40%', left: '40%'}} />
        <img src="/gifs/disco.gif" alt="disco ball" style={{left: '50%', top: '0%', transform: 'translateX(-50%)'}} />
      </Gifs>
    }

    { radMode && <ExplosionClicker /> }

    <div style={{alignSelf: 'end'}}>
      <LogoWrapper to="/">dope domains</LogoWrapper>
      <RandomTagline />
    </div>

    <div>
      <SearchForm onSubmit={handleSubmit}>
        <Input
        autoFocus={!isMobile}
        placeholder="type some words"
        name="searchTerms"
        type="text" />
        <Button type="submit">Find domains</Button>
      </SearchForm>

      <p>
        {'Try '}
        <AppLink to={`/search/cool beats`}>cool beats</AppLink>{', '}
        <AppLink to={`/search/tasty food`}>tasty food</AppLink>{', or '}
        <AppLink to={`/search/dog party`}>dog party</AppLink>
        <br/>
        <br/>
        <br/>
      </p>
    </div>

    {/* <p>
      <img src="/smiley-faces7.gif" alt="dancing smiley" />
    </p> */}

    {/* <Video controls /> */}

    {/* <Well>
      <ul>
        <li>hundreds of TLDs (.com, .io, .xyz, etc)</li>
        <li>suffix and prefix suggestions (add "get-" and "-ly")</li>
        <li>alternative words based on your keywords ("cafe" to "coffee" or "bistro")</li>
        <li>automatically reorder your search terms ("cool cafe" to "cafe cool")</li>
      </ul>
    </Well> */}

    <Footer />

  </HomePage>
)

export default withIsMobile(Home)
