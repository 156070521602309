import styled from 'styled-components'
import ReactGA from 'react-ga'

const PreviewStyle = styled.div`
  position: relative;
  width: 300px;
  height: 200px;
  margin: 0 auto 1rem;
  background: var(--red90);
  display: block;
  background-size: cover;
  box-shadow: 0 0 0 4px var(--red90);

  &:after {
    content: 'Visit site →';
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    background: white;
    display: block;
    padding: .5rem 1rem;
    border-radius: 4px;
    box-shadow: 0 4px 6px var(--shadow);
    text-decoration: none;
    color: var(--textPrimary);
    transition: all .2s ease;
  }

  &:hover:after {
    transform: translateY(-2px);
  }
`
const Preview = PreviewStyle.withComponent(ReactGA.OutboundLink)

const BuyButtonStyle = styled.div`
  position: relative;
  width: 300px;
  height: 200px;
  margin: 0 auto 1rem;
  background: var(--greenLight);
  display: block;

  &:after {
    content: 'Buy now';
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 50%;
    font-size: var(--size-l);
    text-align: center;
    transform: translate(-50%, -50%);
    display: block;
    padding: .5rem 1rem;
    border-radius: 100px;
    box-shadow: 0 4px 6px var(--shadow);
    text-decoration: none;
    background: var(--green);
    color: white;
    transition: all .2s ease;
  }

  &:hover:after {
    transform: translate(-50%, calc(-50% - 2px));
  }
`
const BuyButton = BuyButtonStyle.withComponent(ReactGA.OutboundLink)

export {
  Preview,
  BuyButton,
}
