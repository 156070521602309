import ReactGA from 'react-ga'

const toggleFilter = (prevState, update) => {
  if (update.value) {
    ReactGA.event({
      category: 'User',
      action: 'Open/close filter',
    })
  }

  let filters = Object.assign(prevState.filters, {})
  const filterToChangeIndex = filters.findIndex(f => f.id === update.filter)

  // clear old values
  filters = filters.map(f => {
    f.open = false
    return f
  })

  // new value
  filters[filterToChangeIndex].open = update.value

  return { filters }
}

export default toggleFilter
