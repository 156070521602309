import React from 'react'

class ExplosionClicker extends React.Component {

  state = {
    visible: false,
    clickPosition: {
      x: 110,
      y: 110,
    }
  }
  componentDidMount() {
    window.addEventListener('mousedown', this.pageClick, false)
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.pageClick, false)
  }

  pageClick = event => {
    this.setState({
      visible: true,
      clickPosition: {
        x: event.x,
        y: event.y,
      }
    })

    setTimeout(() => {
      this.setState({
        visible: false,
      })  
    }, 800)  
  }

  render() {
    const left = this.state.clickPosition.x - 30
    const top = this.state.clickPosition.y - 60

    return this.state.visible ? <img
      style={{
        position: 'absolute',
        left,
        top,
      }}
      alt="explosion"
      src="/gifs/Explosion1.gif"
    /> : null
  }
}

export default ExplosionClicker
