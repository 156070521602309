import React from 'react'

import { AppConsumer } from '../../../App'
import {
  Label,
  CheckboxHidden,
  CheckboxVisible,
  FilterItemExample,
} from '../../../styles/filterItem'

const FilterItem = ({filter, item}) => (
  <AppConsumer>
    { ({actions}) => (
      <Label>
        <CheckboxHidden
          type="checkbox"
          checked={item.allow}
          onChange={ event => {
            actions.updateFilterItem({
              filter: filter.id,
              item: item.id,
              value: event.target.checked,
            })
          }}
        />
        <CheckboxVisible checked={item.allow} />
        {item.displayName}
        <FilterItemExample>{item.example}</FilterItemExample>
      </Label>
    )}
  </AppConsumer>  
)

export default FilterItem
