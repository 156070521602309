import React from 'react'

import { AppConsumer } from '../../../App'
import FilterModal from '../filterModal'
import {
  FilterWrapper,
  FilterBox,
  FilterHeader,
  FilterSubHeader,
} from '../../../styles/filter'

const Filter = ({filter}) => {
  const currentlyAllowed = filter.items.filter(f => f.allow).map(f => f.displayName)
  const allowedText = currentlyAllowed.length > 0 ? currentlyAllowed.join(', ') : 'tap to filter'

  return <AppConsumer>
    { ({actions}) => (
      <FilterWrapper>
        <FilterBox
          loading={filter.loading}
          active={currentlyAllowed.length > 0}
          onClick={() => {
            actions.toggleFilter({
              filter: filter.id,
              value: !filter.open,
            })
          }}>
          <FilterHeader>{filter.displayName}</FilterHeader>
          <FilterSubHeader active={currentlyAllowed.length > 0}>{allowedText}</FilterSubHeader>
        </FilterBox>

        <FilterModal filter={filter} actions={actions} />

      </FilterWrapper>
    )}
  </AppConsumer>
}

export default Filter
